import { JobType } from './job-type.model';

interface WorkOrderTuple {
  jobType: string; // ID
  workOrderTemplate: string; // ID
}

/* Enumeration Model */
/**
 * @deprecated Refrain from using this model, use the AppointmentType type instead
 */
class AppointmentTypeModel {
  constructor(
    public name: string,
    public code: string,
    public jobTypes: JobType[],
    public id?: string,
    public emergency?: boolean,
    public meta?: any,
    public workOrders?: WorkOrderTuple[],
    public hh?: boolean,
    public optionalBookingProps?: any,
    public hiddenBookingProps?: any
  ) {}
}

interface AppointmentType {
  name: string;
  code: string;
  supportsElectric?: boolean;
  requiresElectric?: boolean;
  supportsGas?: boolean;
  requiresGas?: boolean;
  emergency?: boolean;
  jobTypes: JobType[];
  id?: string;
  meta?: any;
  hh?: boolean;
  optionalBookingProps?: any;
  hiddenBookingProps?: any;
}

interface AppointmentAndJobType {
  appointmentType;
  jobType;
  dual?: any;
}

const normalizeAppointmentTypeOptions = (rawAppointmentTypes: AppointmentType[], useId?: boolean) => {
  return rawAppointmentTypes.map((rawAppointmentTypesItem) => {
    return {
      value: useId ? rawAppointmentTypesItem.id : rawAppointmentTypesItem.code,
      name: rawAppointmentTypesItem.name,
    };
  });
};

enum AppointmentTypeEnum {
  SCHEDULED_INVESTIGATION = 'SCHEDULED_INVESTIGATION',
  EV = 'EV',
  SOLAR = 'SOLAR',
}

export {
  AppointmentTypeModel,
  AppointmentAndJobType,
  AppointmentTypeEnum,
  AppointmentType,
  normalizeAppointmentTypeOptions,
  WorkOrderTuple,
};
